import './table1_shohin.css'
import React, { useEffect,useState, useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { format } from 'date-fns';
import axios from 'axios';

import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import Modal_zaikolist from './Modal_zaikolist';

//import './TableComponent.css';

const StyledTable = styled.table`
  font-size: 12px; /* 希望のフォントサイズに変更 */
  .custom-header {
    background-color: #007bff !important;
    color: white;
    text-align: center;
  }
    .custom-header-s{
    width:50px;
    }
    .custom-header-m{
    width:200px;
    }
`;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid black', // ボーダーを黒に設定
    boxShadow: state.isFocused ? '0 0 0 1px black' : 'none', // フォーカス時のボーダー
    '&:hover': {
      border: '1px solid black' // ホバー時のボーダー
    }
  })
};

const customStyles_2 = {
  control: (provided, state) => ({
    ...provided,
    marginbottom: '10px',
    border: '1px solid #d3d3d3', /* 薄い灰色の枠線 */
    boxShadow: state.isFocused ? '0 0 0 1px black' : 'none', // フォーカス時のボーダー
    '&:hover': {
      border: '1px solid black' // ホバー時のボーダー
    }
  })
};
const initialData = [
  { date: '2024-01-01', column2: 'test1', column3: 'value3', column4: 'value4',column5: 'value5',column6: 'value6',column7: 'value7',column10: 'value8' },
  { date: '2024-02-01', column2: 'test2', column3: 'value3', column4: 'value4' ,column5: 'value5',column6: 'value6',column7: 'value7',column10: 'value8' },
  // 他のデータも追加
];


function formatDate(dateString) {

  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  //console.log(dateString)
  //console.log(dateString.latest_date)
  //console.log("出力0")

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function formatDate1(dateString) {
  /*console.log("出力1")
  console.log(dateString)
  console.log("出力1")*/
  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}年${month}月${day}日`;
}

  const TableComponent_shohin = React.memo(({ onExport ,}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [pageSizeOptions, setPageSizeOptions] = useState([10, 20, 30, 40, 50, 100]); // ページサイズオプション
    const [initialPageSize, setInitialPageSize] = useState(100); // filteredDataの長さに基づく初期サイズ
    const [sortByState, setSortByState] = useState([]);

  const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_BASE_URL_PROD
  : process.env.REACT_APP_API_BASE_URL_LOCAL;
  console.log(process.env.NODE_ENV)
  console.log(apiUrl)
  
  const [data, setData] = useState(initialData);
   /* const [data, setData] = useState([]);*/
   const [latestDate, setLatestDate] = useState(null);
  
  
   const columns = React.useMemo(
    () => [
  /*
  { Header: '更新日',
    accessor: 'atupdated',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   Cell: ({ value }) => {
    
      return formatDate(value);}, //format(new Date(value), 'yyyy-MM-dd');},
  },*/

  { Header: '品番', 
    accessor: 'ID',
    getHeaderProps: () => ({
      className: 'custom-header',  // ヘッダーだけに適用するクラス
    }),
    getCellProps: () => ({
      className: 'custom-cell',    // データセルに適用するクラス（ここは省略可能）
    }),

   },
   /*
  { Header: '商品名', 
    accessor: 'Name',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },*/
   /*
  { Header: 'トーマス在庫',
     accessor: 'Tormaszaiko',
     getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },
  { Header: '仕入れ予定', 
    accessor: 'Shireyoteisu',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },
  { Header: '出荷待機', 
    accessor: 'Syukkataikisu',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },
  { Header: '未到着', 
    accessor: 'Mitotyakusu',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },
   */

   /*
   { Header: '初期在庫', 
    accessor: 'shoki_zaiko',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },

   { Header: '必要数', 
    accessor: 'Hituyousu',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },

   {
    Header: '過不足',
    accessor: 'kahusokusu',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
    Cell: ({ value }) => (
      <span style={{ color: value < 0 ? 'red' : 'black' }}>
        {value}
      </span>
    ),
  },*/

  /*{ Header: '仕入れ値', accessor: 'Shireprice' },
  { Header: '仕入先', accessor: 'Hiiresaki' },*/
  

  { Header: '仕入先', 
    accessor: 'Hiiresaki',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },
   { Header: '仕入れ値', 
    accessor: 'Shireprice',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },

   { Header: 'カラー', 
    accessor: 'color',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },
   { Header: 'サイズ', 
    accessor: 'size',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },
   {
    Header: 'URL',
    accessor: 'Shiiresaki_URL',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
    Cell: ({ value }) => (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    ),
  },
   { Header: 'メモ', 
    accessor: 'pro_memo',
    getCellProps: () => ({
      className: 'custom-header-m',
      style: { width: '200px' } // セルの幅を固定
    }),
   },


  { 
    Header: '変更',
    accessor: 'C_button1',
    
    getCellProps: () => ({
      className: 'custom-header-s',
      style: { width: '50px' } // セルの幅を固定
    }),
    
    Cell: ({ row }) => (
      <button 
        className="bu_shousai1-1-s" 
        onClick={() => handleButtonClick(row)}
        style={{ width: '50px' }} // ボタン自体の幅を固定
      >
        変更
      </button>
    )
  },
  /*
  { Header: '仕入先', 
    accessor: 'Hiiresaki',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
   },*/
   {
    Header: '削除',
    accessor: 'delete',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),

    Cell: ({ row }) => (
      <input
        type="checkbox"
        onChange={() => handleCheckboxChange(row)}
        checked={selectedRows.includes(row.original.ID)}
      />
    ),
    // カスタムソートタイプを追加
  sortType: (rowA, rowB) => {
    const aSelected = selectedRows.includes(rowA.original.ID) ? 1 : 0;
    const bSelected = selectedRows.includes(rowB.original.ID) ? 1 : 0;
    return aSelected - bSelected;
  },

  },
  /*
  {
    Header: 'URL',
    accessor: 'Shiiresaki_URL',
    getHeaderProps: () => ({
      className: 'custom-header',
    }),
    Cell: ({ value }) => (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    ),
  },*/

],[selectedRows]);
   // 削除ボタンの処理
     // カスタムソートタイプを追加
  

     // チェックボックスの変更を処理
  const handleSelectAll = () => {
    if (isAllSelected) {
      // 全解除
      setSelectedRows([]);
    } else {
      // 全選択
      const allIds = filteredData.map(row => row.ID); // 全ての行のIDを取得
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // チェック状態を反転
  };

  const handleCheckboxChange = (row) => {
    const id = row.original.ID;
    console.log("チェックボックス")
    console.log(id)
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

   const handleDelete = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}?action=deleteRows-dB`,  // APIのエンドポイント
        { ids: selectedRows },           // 削除するIDのリストを送信
        {
          headers: {
            'Content-Type': 'application/json',
            // 必要に応じて他のヘッダーを追加
          },
          withCredentials: true,          // 認証情報 (Cookie) を送信する場合に必要
        }
      );
      
      console.log('Delete response:', response.data);  // レスポンスをログ出力
      alert('選択された行が削除されました');
      
      // 削除後、データをリロードするかテーブルを更新する処理をここに追加
      fetchData_set();
    } catch (error) {
      console.error('Error deleting rows:', error);
      alert('削除に失敗しました');
    }
  };

  const handleDeleteALL = async () => {
      try {
        // 選択された行のIDリストを取得
        const response = await axios.post(
          `${apiUrl}?action=deleteRowsALL`,  // PHP側のエンドポイント
          { ids: selectedRows },          // 削除するIDのリストを送信
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,         // 認証情報 (Cookie) を送信する場合に必要
          }
        );
        
        console.log('Delete response:', response.data);  // レスポンスをログ出力
        //alert('選択された行のtable_showがHIDEに設定されました');
        
        // 削除後にテーブルを更新する
        //fetchData_set(); // テーブルを再度フェッチする関数
      } catch (error) {
        console.error('Error updating rows:', error);
        alert('行の更新に失敗しました');
      }
}

/*発注ボタン */
const handleButtonClick = (row) => {

  setSelectedRow(row.original);
  setid(row.original.ID)
  setName(row.original.Name)
  setTormaszaiko(row.original.Tormaszaiko)
  setShireyoteisu(row.original.Shireyoteisu)
  setSyukkataikisu(row.original.Syukkataikisu)
  setMitotyakusu(row.original.Mitotyakusu)
  setHituyousu(row.original.Hituyousu)
  setkahusokusu(row.original.kahusokusu)
  setShireprice(row.original.Shireprice)
  setHiiresaki(row.original.Hiiresaki)
  setShiesakiID(row.original.ShiesakiID)
  SetShiiresaki_URL(row.original.Shiiresaki_URL)
  Setave_nouki(row.original.ave_nouki)

  setcolor(row.original.color)
  setsize(row.original.size)
  setmemo_p(row.original.pro_memo)
  setShop_name(row.original.Shop_name)
 
  /*
  const client_data ={
    value: row.original.ShiesakiID,
    label:row.original.Hiiresaki,
  };

  setSelectedOption(client_data);
  */
  
  setShow(true);
};

const handleClose= () => {
  setShow(false);
  setSelectedRow(null);
};
const [id, setid] = useState('');
const [name, setName] = useState('');
const [Tormaszaiko, setTormaszaiko] = useState('');
const [Shireyoteisu, setShireyoteisu] = useState('');
const [Syukkataikisu, setSyukkataikisu] = useState('');
const [Mitotyakusu, setMitotyakusu] = useState('');
const [Hituyousu, setHituyousu] = useState('');
const [kahusokusu, setkahusokusu] = useState('');
const [Shireprice, setShireprice] = useState('');
const [Hiiresaki, setHiiresaki] = useState('');
const [ShiesakiID, setShiesakiID] = useState('');	

const [Hatyuusu, setHatyuusu] = useState('');	
const [Shiiresaki_URL, SetShiiresaki_URL] = useState('');

const [color, setcolor] = useState('');
const [size, setsize] = useState('');
const [emo_p, setmemo_p] = useState('');
const [Shop_name, setShop_name] = useState('');

const [ave_nouki, Setave_nouki] = useState('');
const [show, setShow] = useState(false);
const [selectedRow, setSelectedRow] = useState(null);
	

  /*最終更新日 */
  useEffect(() => {
    const fetchLatestDate = async () => {
      try {
        const response = await axios.get(`${apiUrl}?action=getLatestZaikoUpdate`,
        {
          headers: {
            'Content-Type': 'application/json',
            // 他に必要なヘッダーを追加
          },
          withCredentials: true, // 認証情報 (Cookie) を送信する場合に必要
        }
        );
      //console.log('Full response:', response); // レスポンス全体をログ出力
      console.log('Data:', response.data); // レスポンスのdata部分をログ出力
      setLatestDate(response.data.latest_date); 
      } catch (error) {
        console.error('Error fetching latest date:', error);
      }
    };
 
     fetchLatestDate();
   }, []);

  /*テーブルデータ取得 */
  useEffect(() => {
    fetchData_set();//⇒最初は読み込まなし
    //handleDeleteALL();//showをALLクリア
  }, []);




  const fetchData_set = async () => {
      try {
        const response = await axios.get(`${apiUrl}?action=getproduct`,
          {
            headers: {
              'Content-Type': 'application/json',
              // 他に必要なヘッダーを追加
            },
            withCredentials: true, // 認証情報 (Cookie) を送信する場合に必要
          }

        );
        console.log(response)
        console.log("==================================")
        console.log(response.data)
        const resultData = response.data.map(row => ({
          atupdated:row.atupdated ||'',
          ID: row.ID || '',
          Name: row.Name || '',
          Tormaszaiko: row.Tormaszaiko || 0,
          Shireyoteisu: row.Shireyoteisu || 0,
          Syukkataikisu: row.Syukkataikisu || 0,
          Mitotyakusu: row.Mitotyakusu || 0,
          Hituyousu: row.Hituyousu || 0,
          kahusokusu: row.kahusokusu || 0,

          Shireprice: row.Shireprice || '',
          Hiiresaki: row.Hiiresaki || '',
          ShiesakiID: row.ShiesakiID || '',


          Shiiresaki_URL: row.Shiiresaki_URL || '',
          Shop_name:row.Shop_name || '',

          ave_nouki: row.ave_nouki||0,

          shoki_zaiko : row.shoki_zaiko||0,
          
          color : row.color|| '',
          size : row.size|| '',
          pro_memo : row.pro_memo|| '',

        }));
        console.log(resultData)
        setData(resultData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    /*
    useEffect(() => {
      updateTableData.current = fetchData_set; // updateTableData関数を更新
    }, [fetchData_set]);
    */

    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
       console.log(id)
        const response = await axios.post(`${apiUrl}?action=updateHatyuList2`, { id,name,Tormaszaiko, Shireyoteisu,Syukkataikisu,Mitotyakusu, Hituyousu,kahusokusu,Shireprice,Hiiresaki,ShiesakiID,Shiiresaki_URL,color,size,emo_p,Shop_name},
         
        {
          headers: {
            'Content-Type': 'application/json',
            // 他に必要なヘッダーを追加
          },
          withCredentials: true, // 認証情報 (Cookie) を送信する場合に必要
        }
        );
        if (response.status === 200) {
          fetchData_set();
          alert('更新が完了しました');
          handleClose();

          
        }
      }catch (error) {
        if (error.response) {
          console.error('Error status', error.response.status);
          console.error('Error data', error.response.data);
        } else {
          console.error('Error', error.message);
        }
        alert('データの登録に失敗しました');
      }
    };

    const handleSubmit1 = async (e) => {
      e.preventDefault();
  
      try {
       console.log(id)
        const response = await axios.post(`${apiUrl}?action=addHatyuList`, { id,name,Tormaszaiko, Shireyoteisu,Syukkataikisu,Mitotyakusu, Hituyousu,kahusokusu,Shireprice,Hiiresaki,ShiesakiID,Hatyuusu,Shiiresaki_URL,color,size,emo_p,Shop_name},
                 {
          headers: {
            'Content-Type': 'application/json',
            // 他に必要なヘッダーを追加
          },
          withCredentials: true, // 認証情報 (Cookie) を送信する場合に必要
        }
        );
        if (response.status === 200) {
          fetchData_set();
          alert('追加が完了しました');
          handleClose();
          
        }
      } catch (error) {
        alert('データの登録に失敗しました');
        console.error('Error adding data:', error);
      }
    };


    /*モーダル　企業リスト */
    const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(Hiiresaki);

    const handleSelectChange  = (selected) => {
      

      if (selected) {
        setSelectedOption(selected);
        setHiiresaki(selected.label);
        setShiesakiID(selected.value);
      } else {
        setSelectedOption(null);
        setHiiresaki('');
        setShiesakiID('');
      }
      
    };

    const handleChange_hituyousu_ = (e) => {
      const value = e.target.value;
      console.log("更新")
      console.log( e.target.value)
      setHituyousu(value);
      const kahusoku_su_new = parseFloat(Tormaszaiko) + parseFloat(Shireyoteisu) -parseFloat(Syukkataikisu) + parseFloat(Mitotyakusu) -parseFloat(value)
      console.log(kahusoku_su_new)
      setkahusokusu(kahusoku_su_new)

    };
    
    /*2024 09 06 必要なし
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${apiUrl}?action=getClients`,
        
        {
          headers: {
            'Content-Type': 'application/json',
            // 他に必要なヘッダーを追加
          },
          withCredentials: true, // 認証情報 (Cookie) を送信する場合に必要
        }

          ); // エンドポイントはサーバー側で設定します
          const data = response.data.map(client => ({
            value: client.ID,
            label: client.Campnayname,
          }));
          setOptions(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);
     */
    
    /*以下テーブル設定 */
  const [searchInput, setSearchInput] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const updateData = (rowIndex, columnId, value) => {
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };


  const filteredData = useMemo(() => {
    return data.filter(row => {
      const date = new Date(row.atupdated);
      
      // 日付フィルタ
      const isWithinDateRange =
        (!startDate || date >= startDate) && (!endDate || date <= endDate);
      
      // 検索フィルタ
      const matchesSearchInput = Object.values(row).some(value =>
        String(value).toLowerCase().includes(searchInput.toLowerCase())
      );
      


    return isWithinDateRange && matchesSearchInput ;
      
    });
  }, [data, searchInput, startDate, endDate]);


  
  // 100の位で丸める関数（切り上げ）
  const roundUpToHundred = (num) => {
    return Math.ceil(num / 100) * 100;
  };

  // filteredDataが更新されたときに、ページサイズオプションを更新する
 /*
  useEffect(() => {
    const maxPageSize = roundUpToHundred(filteredData.length);
    if(maxPageSize>100){
    setPageSizeOptions([10, 20, 30, 40, 50, 100, maxPageSize]);
    setInitialPageSize(maxPageSize); // ページサイズの初期値をmaxPageSizeに更新
    setPageSize(maxPageSize)
  }

  }, [filteredData]); // filteredDataが更新されるたびに実行
*/
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    
    //state: { pageIndex, pageSize,  sortBy: currentSortBy },
    state,
    rows, // ソート前のデータをエクスポートするために使用
  } = useTable(
    {
      columns,
      
      data: filteredData,
      //initialState: { pageIndex: 0 },
      initialState: { pageIndex: 0 , pageSize:initialPageSize, sortBy: sortByState },

      //initialState: { pageIndex: 0, pageSize: filteredData.length },

      updateData,

      //manualSortBy: true, // 手動ソートを有効化（これがないと外部管理が難しい）
    },
    useSortBy,
    
    usePagination
  );


    // 親コンポーネントに rows と columns を渡す
    React.useEffect(() => {
      if (onExport) {
        onExport(rows, columns);
      }
    }, [rows, columns] );//onExport]);

  // ソート状態が変わるたびに保存する
  // stateからsortByを取得
  const { pageIndex, pageSize, sortBy } = state;
  useEffect(() => {
    setSortByState(sortBy); // sortByで現在のソート状態を取得
  }, [sortBy]);
  
  return (
    <div>
      <span>最新更新日:　{formatDate1(latestDate)}</span>
      <div className="controls-s">
        <div>フリーワード検索</div>
        <input
          value={searchInput}f
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Search"
          className="controls_date-s"
        />
        {/*
        <div>日付</div>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className="controls_date"
        />

        <div>～</div>
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
          className="controls_date"
        />*/}
      </div>
      
      {/* フィルタドロップダウン */}
      <div className = "c-button-fusoku">
        <button className="dl-button"onClick={handleDelete}>削除</button>
      </div>

      <div className="pagination">
        <button id = "pg_bu1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span id="pg_sp">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeOptions.map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>

        {/* 全選択チェックボックス */}
      <div className='all_dl_ck'>
        <label>
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAll}
          />
          全選択
        </label>
      </div>

      </div>




      <StyledTable  {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    backgroundColor: '#f2f2f2', // 背景色を設定
                    color: 'black', // 文字色を設定
                    padding: '10px', // パディングを設定
                    textAlign: 'center', // テキストの配置を設定
                  }}
                >
                  
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  // 共通のスタイル定義
                  const isDeleteOrButton = cell.column.id === 'delete' || cell.column.id === 'C_button1';

                  return (
                    <td
                      style={{
                        maxWidth: isDeleteOrButton ? '50px' : '150px',   // delete または C_button1 のとき 50px、他は150px
                        textAlign: isDeleteOrButton ? 'center' : 'left', // delete または C_button1 のとき 中央寄せ、他は左寄せ

                        overflow: 'hidden',         // はみ出た部分を隠す
                        whiteSpace: 'nowrap',       // テキストを1行にする
                        textOverflow: 'ellipsis',   // はみ出たテキストを省略記号で表示
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>


      <Modal_zaikolist show={show} handleClose={handleClose}>
        {selectedRow && (
          <div id="C_shiire_toroku" className="top-container37">
          <div className="top-containerhattyukanri1">
            <div className="top-container38">
              <span className="top-text31">編集</span>
            </div>

            <form onSubmit={handleSubmit}>
            <div className="top-container39">
            <div className="product-info-top">
                <div className="product-info1">

                  <div className="top-container40">
                    <div className="top-container41">
                      <span className="top-text32">商品名</span>
                      <input
                        type="text"
                        value = {name}
                        className="top-textinput16 input"
                        id = "input-readOnly"
                        readOnly
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="top-container42">
                      <span className="top-text33">品番</span>
                      <input
                        type="text"
                        value = {id}
                        onChange={(e) => setid(e.target.value)}
                        readOnly
                        id = "input-readOnly"
                        className="top-textinput17 input"
                      />
                    </div>
                  </div>

                  <div className="top-container43">
                    <div className="top-container44">
                      <span className="top-text34">トーマス在庫</span>
                      <input
                        type="text"
                        value = {Tormaszaiko}
                        readOnly
                        className="top-textinput18 input"
                        id = "input-readOnly"
                        onChange={(e) => setTormaszaiko(e.target.value)}
                      />
                    </div>
                    <div className="top-container45">
                      <span className="top-text35">仕入れ予定数</span>
                      <input
                        type="text"
                        value = {Shireyoteisu}
                        className="top-textinput19 input"
                        readOnly
                        id = "input-readOnly"
                        onChange={(e) => setSyukkataikisu(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="top-container48-1">
                    <div className="top-container49-1">
                      <span className="top-text37">出荷待機数</span>
                      <input
                        type="text"
                        value = {Syukkataikisu}
                        className="top-textinput21-1 input"
                        readOnly
                        id = "input-readOnly"
                        onChange={(e) => setSyukkataikisu(e.target.value)}
                      />
                    </div>

                      <div className="top-container51-1">
                        <span className="top-text38">未到着在庫</span>
                        <input
                          value = {Mitotyakusu}
                          className="top-textinput21-2 input"
                          readOnly
                          id = "input-readOnly"
                          onChange={(e) => setMitotyakusu(e.target.value)}

                        ></input>
                    </div>

                  </div>

                  <div className="top-container48-1">

                      <div className="top-container51-11">
                        <span className="top-text38">平均納期</span>
                        <input
                          value = {ave_nouki}
                          className="top-textinput21-2 input"
                          readOnly
                          id = "input-readOnly"
                          
                        ></input>
                      </div>
                      <div className="top-container51-1">
                        <span className="top-text38">過不足数</span>
                        <input
                          value = {kahusokusu}
                          className="top-textinput21-2 input"
                          readOnly
                          id = "input-readOnly"
                          onChange={(e) => setkahusokusu(e.target.value)}

                        ></input>
                      </div>

                  </div>
                </div>

                <div className="product-info1">
                  <div className="top-container48-1-h">
                    <div className="top-container49-1">
                      <span className="top-text37">必要数</span>
                      <input
                        type="text"
                        value = {Hituyousu}
                        className="top-textinput21-1 input"
                        
                        id = "input-readOnly"
                        readOnly
                        onChange={handleChange_hituyousu_}
                      />
                    </div>

                    <div className="top-container51-1">
                        <span className="top-text38">ショップ名</span>
                        <input
                        value = {Shop_name}
                          className="top-textinput21-2 input"
                          id = "input"
                          onChange={(e) => setShop_name(e.target.value)}
                          
                        ></input>
                      </div>

                  </div>


                  <div className="top-container48-1-sh">
                    <div className="top-container49-1">
                      <span className="top-text37">仕入れ値</span>
                      <input
                        type="text"
                        value = {Shireprice}
                        className="top-textinput21-1 input"
                        id = "input"
                        onChange={(e) => setShireprice(e.target.value)}
                      />
                    </div>

                    <div className="top-container51-1">
                        <span className="top-text38">発注数</span>
                        <input
                          className="top-textinput21-2 input"
                          
                          id = "input-readOnly"
                          onChange={(e) => setHatyuusu(e.target.value)}
                          
                        ></input>
                      </div>

                  </div>
                  <div className="top-container48-1-co-1">
                    <div className="top-container49-1">
                      <span className="top-text37">カラー</span>
                      <input
                        type="text"
                        value = {color}
                        className="top-textinput21-1 input"
                        id = "input"
                        onChange={(e) => setcolor(e.target.value)}
                      />
                    </div>

                    <div className="top-container51-1">
                        <span className="top-text38">サイズ</span>
                        <input
                        value = {size}
                          className="top-textinput21-2 input"
                          id = "input"
                          onChange={(e) => setsize(e.target.value)}
                          
                        ></input>
                      </div>

                  </div>


                  <div className="top-container48-1">
                    <div className="top-container49-1">
                      <span className="top-text37">取引先名</span>                    
                      <input
                          className="top-textinput21-2 input"
                          id = "input"
                          value = {Hiiresaki}
                          onChange={(e) => setHiiresaki(e.target.value)}

                        ></input>
                    </div>

                      <div className="top-container51-1">
                        <span className="top-text38">取引先名URL</span>
                        <input
                          className="top-textinput21-2 input"
                          id = "input"
                          value = {Shiiresaki_URL}
                          
                          onChange={(e) => SetShiiresaki_URL(e.target.value)}
                          
                        ></input>
                      </div>
                  </div>

                  

                </div>
              </div>
              <div className="top-container50">
                    <div className="top-container51-m">
                      <span className="top-text38">メモ</span>
                    </div>
                    <textarea
                      placeholder="メモ"
                      className="top-textarea-m textarea"
                      value = {emo_p}
                      onChange={(e) => setmemo_p(e.target.value)}

                    ></textarea>
                  </div>

              <div className="top-container52-1-save">
                <button  type="submit" className="top-button11-1 button">
                  保存する
                </button>

              </div>
            </div>


            </form>
          </div>
        </div>
        )}
      </Modal_zaikolist>

    </div>
  );
})

export default TableComponent_shohin;


